<template>
  <!-- <div> -->

  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])">
    <CSidebarBrand class="d-md-down-none" to="/"> 
        <img src="../assets/logo.png" class="c-sidebar-brand-full" size="custom-size" height="25" />
        <img src="../assets/logo-mini.png" class="c-sidebar-brand-minimized" size="custom-size" height="38" />
    </CSidebarBrand>
    <CRenderFunction flat :content-to-render="auth.user && auth.user.roles[0].name.includes('Admin')?$options.adminnav:$options.usernav"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
  <!-- </div> -->
</template>

<script>
import adminnav from './_adminnav'
import usernav from './_usernav'

import { mapState } from "vuex";

export default {
  name: 'TheSidebar',
  adminnav,
  usernav,
  async mounted(){
    await this.$store.dispatch("auth/fetchUser");
  },
  computed: {
    ...mapState(["auth"]),
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  }
}
</script>
