export default [{
    _name: 'CSidebarNav',
    _children: [{
            _name: 'CSidebarNavItem', 
            name: 'Dashboard', //name that will appear 
            to: '/dashboard', // the page that will be displayed when the navigation item is being clicked
            icon: 'cil-speedometer', // the icon which will show beside navigation item  name
        },
        {
            _name: 'CSidebarNavTitle',
            _children: ['Modules']
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Questions',
            to: '/questions',
            icon: 'cil-file'
        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'Deduplication',
            route: '/deduplication',
            icon: 'cil-puzzle',
            items: [
                {
                    name: 'Possible Duplicates',
                    to: '/deduplications'
                },
                {
                    name: 'Deleted Duplicates',
                    to: '/deduplication-history'
                },
                {
                    name: 'Not Duplicates',
                    to: '/deduplication-deleted'
                }
            ]
        },
    ]
}]